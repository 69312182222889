<template>
  <div class="divBody">
    <div><h1 class="h1text">- Products -</h1></div>
    <v-row class="firstrow">
      <v-col align="center">
        <br />■
        <span class="text1">3D人体モデル＆アバター生成事例</span>
        <p></p>
        <a href="https://sample3js.web.app/ina_dance.html" target="_blank"
          ><v-img
            class="img1"
            src="../assets/image.img/image1.png"
            height="400px"
            width="400px"
          ></v-img
        ></a>
      </v-col>
      <v-col align="center">
        <br />■ <span class="text1">工業製品のモデル表示</span>
        <p></p>
        <a href="https://sample3js.web.app/GXB_2.html" target="_blank"
          ><v-img
            class="img2"
            src="../assets/image.img/image4.png"
            height="400px"
            width="400px"
          ></v-img></a
      ></v-col>
      <v-col align="center">
        <br />■ <span class="text1">点群を用いた空間の制作</span>
        <p></p>
        <a href="https://sample3js.web.app/aoyama_FBX.html" target="_blank"
          ><v-img
            class="img3"
            src="../assets/image.img/image6.png"
            height="400px"
            width="400px"
          ></v-img
        ></a> </v-col
    ></v-row>

    <v-row class="secondrow">
      <v-col align="center">
        <br />■
        <span class="text1">iPhoneでのスキャニング（点群）</span>
        <p></p>
        <a href="https://sample3js.web.app/jihanki.html" target="_blank"
          ><v-img
            class="img4"
            src="../assets/image.img/image3.png"
            height="400px"
            width="400px"
          ></v-img></a
      ></v-col>
      <v-col align="center">
        <br />■ <span class="text1">3D人体モデル生成事例2</span>
        <p></p>
        <a href="https://sample3js.web.app/maeda.html" target="_blank"
          ><v-img
            class="img5"
            src="../assets/image.img/image5.png"
            height="400px"
            width="400px"
          ></v-img></a
      ></v-col>
      <v-col align="center">
        <br />■
        <span class="text1">3D人体モデル＆モーション追加</span>
        <p></p>
        <a href="https://sample3js.web.app/maeda_dance.html" target="_blank"
          ><v-img
            class="img6"
            src="../assets/image.img/image2.png"
            height="400px"
            width="400px"
          ></v-img></a></v-col
    ></v-row>
    <div><h1 class="h2text">- Services -</h1></div>
    <v-row class="lastrow">
      <v-col align="center">
        <br />■ <span class="texts">vnow</span>
        <p></p>
        <a href="https://vnow.jp/" target="_blank">
          <v-img
            class="vnowimg"
            src="https://pbs.twimg.com/profile_images/1405358234152574985/zodatBhF_400x400.jpg"
            height="400px"
            width="300px"
          ></v-img></a
      ></v-col>

      <v-col align="center">
        <br />■ <span class="texts">cococaso</span>
        <p></p>
        <a href="https://cococaso.com/" target="_blank"
          ><v-img
            class="cococasoimg"
            src="https://prtimes.jp/i/37896/6/ogp/d37896-6-86e6cd305e9367743c78-1.png"
            height="400px"
            width="300px"
          ></v-img></a
      ></v-col>
      <v-col align="center">
        <br />■ <span class="texts">vmarche</span>
        <p></p>
        <a href="https://vmarche.jp/" target="_blank"
          ><v-img
            class="vmarchimg"
            src="https://pbs.twimg.com/profile_images/1380080920665853954/BHx9vVM1_400x400.jpg"
            height="400px"
            width="300px"
          ></v-img></a
      ></v-col>
      <v-col align="center">
        <br />■ <span class="texts">Egg Saurus</span>
        <p></p>
        <a href="https://eggsaurus.com/" target="_blank"
          ><v-img
            class="eggsaurusimg"
            src="https://pbs.twimg.com/profile_images/1457684209813057536/Lr7Dk6yI_400x400.jpg"
            height="400px"
            width="300px"
          ></v-img></a
      ></v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "bodyPage",

  data: () => ({}),
};
</script>
<style>
.img1 {
  display: inline-block;
}
.img2 {
  display: inline-block;
}
.img3 {
  display: inline-block;
}
.img4 {
  display: inline-block;
}
.img5 {
  display: inline-block;
}
.img6 {
  display: inline-block;
}
.divBody {
  background-color: rgba(111, 175, 201, 0.391);
  padding-top: 30px;
  padding-bottom: 130px;
  border-top: 3px solid rgba(17, 31, 224, 0.414);
  border-bottom: 3px solid black;
}
.h1text {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.h2text {
  text-align: center;
  padding-top: 120px;
}
.firstrow {
  margin-bottom: 100px;
}
.text1 {
  font-size: 20px;
}
.texts {
  font-size: 25px;
}
.lastrow {
  padding-top: 30px;
}
.vnowimg {
  border-radius: 50%;
  border: 2px solid black;
  display: inline-block;
}
.cococasoimg {
  border-radius: 30px;
  border: 2px solid black;
  display: inline-block;
}
.vmarchimg {
  border-radius: 30px;
  border: 2px solid black;
  display: inline-block;
}
.eggsaurusimg {
  border-radius: 50%;
  border: 2px solid black;
  display: inline-block;
}
</style>
