<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="display-2 font-weight-bold mb-3"></h1>
      </v-col>
      <div class="empty"></div>
      <v-col cols="12">
        <v-img
          alt="Romantech Photo"
          src="https://vr.romantech.co.jp/wp-content/uploads/2020/02/logo_1300_500.jpg"
          height="300"
          contain
        ></v-img>
        <h1
          class="subheading font-weight-regular"
          :style="{ marginTop: '120px', marginBottom: '30px' }"
        >
          <span>＜サンプル事例＞</span>
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
<style>
.empty {
  padding-top: 100px;
}
</style>
