<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center"></div>

      <v-spacer></v-spacer>

      <!-- <v-btn href="https://romantech.co.jp/#/" target="_blank" text>
        <span class="mr-2">To Romantech's Home Page</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main> <HelloWorld /> </v-main><bodyPage />
    <newFooterPage />
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";
import newFooterPage from "./components/newFooterpage.vue";
import bodyPage from "./components/bodyPage.vue";

export default {
  name: "App",

  components: {
    HelloWorld,
    newFooterPage,
    bodyPage,
  },

  data: () => ({
    //
  }),
};
</script>
