<template>
  <div class="footerDiv">
    <span class="romantechtext">©2021 — Romantech Japan Co., Ltd.</span>
  </div>
</template>
<script>
export default {
  name: "newFooterPage",

  data: () => ({}),
};
</script>
<style>
.footerDiv {
  height: 80px;
  background-color: rgba(0, 0, 0, 0.702);
}
.romantechtext {
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
  text-align: center;

  color: white;
  margin-top: 40px;
}
</style>
